import { useEffect, useState } from 'react'

type UseWindowSize = () => {
  width: number | undefined
  height: number | undefined
}

export const useWindowSize: UseWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  const isClient = typeof window !== 'undefined'

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    if (isClient) {
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [isClient])

  return windowSize
}
