import { NextApiRequest, NextApiResponse } from 'next'
import url from 'url'
import jwtDecode from 'jwt-decode'
import auth0 from './auth0'
import routes from '../routes'

type checkIfAuthorizedProps = {
  scopes: string[]
  permissions: string[]
}

export const checkIfAuthorized = ({ scopes, permissions }: checkIfAuthorizedProps): boolean => {
  return scopes.filter((s) => !permissions.includes(s)).length === 0
}

type GetStatusCodeFromContext = {
  req: NextApiRequest
  res: NextApiResponse
}

export const getStatusCodeFromContext = async (ctx: GetStatusCodeFromContext): Promise<number> => {
  const { req, res } = ctx

  try {
    let pathname = ctx.req.url

    const match = /\/_next\/data\/\w*(\/\w*).json/g.exec(pathname)

    if (match) {
      pathname = match[1]
    }

    const urlParts = url.parse(pathname, true)
    const routePath = pathname.replace(urlParts.search, '')
    const route = routes.find((r) => r.pathname === routePath)
    const session = await auth0.getSession(req)

    if (!session) {
      if (route?.requireAuth) {
        return 401
      }

      if (
        urlParts.search &&
        route.pathRequiringAuth &&
        route.pathRequiringAuth.find((p) => urlParts.search.includes(p))
      ) {
        return 401
      }
    }

    if (!route?.scopes || !route?.scopes.length) {
      return 200
    }

    const tokenCache = auth0.tokenCache(req, res)
    const { accessToken } = await tokenCache.getAccessToken()

    const jwtData = jwtDecode(accessToken)
    const scopes = route?.scopes || []
    const permissions = jwtData?.['https://clients.pilot.traefik.io/permissions']

    if (
      checkIfAuthorized({
        scopes,
        permissions,
      })
    ) {
      return 200
    } else {
      return 403
    }
  } catch (error) {
    console.error(error)
    return 403
  }
}

export const getAccessToken = async (req, res, requestedScopes = []) => {
  const authorizationHeader = req.headers['authorization']
  const apiKey = req.headers['x-api-key']

  if (authorizationHeader && apiKey === process.env.API_KEY) {
    return authorizationHeader.slice(7)
  }

  const tokenCache = auth0.tokenCache(req, res)

  const cache = await tokenCache.getAccessToken({
    scopes: requestedScopes,
  })

  return cache.accessToken
}
