// eslint-disable-next-line @typescript-eslint/no-var-requires
const { ROOT_URL } = require('./config')

const REDIRECT_URI = `${ROOT_URL}/api/callback`
const POST_LOGOUT_REDIRECT_URI = `${ROOT_URL}?redirect-reason=logout`

let config = {}

if (typeof window === 'undefined') {
  /**
   * Settings exposed to the server.
   */
  config = {
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
    AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
    AUTH0_SCOPE: process.env.AUTH0_SCOPE,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    REDIRECT_URI,
    POST_LOGOUT_REDIRECT_URI,
    AUTH0_COOKIE_SECRET: process.env.AUTH0_COOKIE_SECRET,
    AUTH0_COOKIE_LIFETIME: process.env.AUTH0_COOKIE_LIFETIME,
  }
} else {
  /**
   * Settings exposed to the client.
   */
  config = {
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
    AUTH0_SCOPE: process.env.AUTH0_SCOPE,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    REDIRECT_URI,
    POST_LOGOUT_REDIRECT_URI,
  }
}

module.exports = config
