import breakpoints from '../utils/breakpoints'
import styled from 'styled-components'
import { Box } from '@traefiklabs/faency'

export const containerStyle = `
  margin: 0 12px;

  @media (min-width: ${breakpoints.laptop}) {
    margin: 0 24px;
  }

  @media (min-width: ${breakpoints.laptop}) {
    width: 100%;
    max-width: ${breakpoints.laptop};
    margin: 0 auto;
  }
`

export const noGutterContainerStyle = `
  margin: 0;

  @media (min-width: ${breakpoints.laptopL}) {
    max-width: ${breakpoints.laptopL};
    margin: 0 auto;
  }
`

export type Props = {
  noGutter?: boolean
}

export default styled(Box)`
  ${(props: Props) => (props.noGutter ? noGutterContainerStyle : containerStyle)}
`
