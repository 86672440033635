import 'isomorphic-unfetch'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { Route } from '../routes'
import { checkIfAuthorized } from '../utils/permissions'
import fetcher from '../utils/fetcher'
import useAuth from '../hooks/use-auth'

type UseAuthorizedRoutes = {
  authorizedRoutes: Route[]
}

const useAuthorizedRoutes = (routes: Route[]): UseAuthorizedRoutes => {
  const { user } = useAuth()
  const MAX_SAFE_NUMBER = Math.pow(2, 31) - 1
  const { data: permissionsData } = useSWR('/api/permissions', fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 0,
    dedupingInterval: MAX_SAFE_NUMBER,
  })
  const userPermissions = permissionsData?.data || []

  const authorizedRoutes: Route[] = routes.filter(
    (route) =>
      (route?.scopes ? checkIfAuthorized({ scopes: route.scopes, permissions: userPermissions }) : true) &&
      (!route?.requireAuth || user),
  )

  return { authorizedRoutes }
}

export default useAuthorizedRoutes
